import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { authStore } from './stores/authStore';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const handleErrors = error => {
  if (error && error.response && error.response.status === 401) {
    authStore.logout();
  }
  return error;
};

const responseBody = res => res.body;

const tokenPlugin = req => {
  const token = window.localStorage.getItem('jwt');
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody)
};

// prettier-ignore
const Auth = {
  current: () => requests.get('/user'),
  login: (userid, password, otp) =>
    requests.post('/users/login', { userid, password, otp }),
  register: (email, password, otp) =>
    requests.post('/users', { email, password, otp }),
  save: user => requests.put('/user', { ...user }),
  getOtp: email =>
    requests.get(`/users/otp/${email}`)
};

// prettier-ignore
const limit = (count, page) => `limit=${count}&offset=${page ? page * count : 0}`;

// prettier-ignore
const Profile = {
  get: username =>
    requests.get(`/profiles/${username}`),
  files: () =>
    requests.get('/files')
};

// prettier-ignore
const Timeline = {
  all: (page, lim = 20) =>
    requests.get(`/timelines/self?${limit(lim, page)}`),
  byAuthor: (username, page, lim=20) =>
    requests.get(`/timelines?${limit(lim, page)}&author=${username}`),
  get: slug =>
    requests.get(`/timelines/${slug}`),
  edit: (slug, data) =>
    requests.put(`/timelines/${slug}`, data),
  delete: slug =>
    requests.del(`/timelines/${slug}`),
  create: data =>
    requests.post('/timelines', data),
  getAllEvents: slug =>
    requests.get(`/events/${slug}`),
  getAllTJSEvents: slug =>
    requests.get(`/events/${slug}/tjs`),
  createEvent: (slug, data) =>
    requests.post(`/event/${slug}`, data),
  editEvent: (eventId, data) =>
    requests.put(`/event/${eventId}`, data),
  deleteEvent: eventId =>
    requests.del(`/event/${eventId}`),
  upload: data =>
    requests.post('/upload', data)
}

export default {
  Auth,
  Profile,
  Timeline
};
