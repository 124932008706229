import { createContext } from 'react';
import { observable, decorate, computed, action } from 'mobx';
import agent from '../agent';

class TimelineStore {
  isLoading = false;
  hasNextPage = true;
  count = 0;
  timelinePage = 0;
  currentTimeline = null;
  timelineRegistry = new Map();
  timelineEventsRegistry = new Map();
  filesRegistry = new Map();

  setCurrentTimeline(timeline) {
    this.currentTimeline = timeline;
  }

  // prettier-ignore
  get timelines() {
    const timelinesArr = [];
    for (let [key, value] of this.timelineRegistry) { // eslint-disable-line no-unused-vars
      timelinesArr.push(value);
    }
    return timelinesArr;
  }

  // prettier-ignore
  get events() {
    const eventsArr = [];
    for (let [key, value] of this.timelineEventsRegistry) { // eslint-disable-line no-unused-vars
      eventsArr.push(value);
    }
    eventsArr.sort((a, b) => a.start_year.localeCompare(b.start_year) ||
      a.start_month.localeCompare(b.start_month) ||
      a.start_day.localeCompare(b.start_day))
    return eventsArr;
  }

  // prettier-ignore
  get files() {
    const filesArr = [];
    for (let [key, value] of this.filesRegistry) { // eslint-disable-line no-unused-vars
      filesArr.push(value);
    }
    return filesArr;
  }

  clear() {
    this.timelineRegistry.clear();
    this.timelinePage = 0;
    this.hasNextPage = true;
    this.count = 0;
  }

  setPage(page) {
    this.timelinePage = page;
  }

  createTimeline(title, description) {
    return agent.Timeline.create({ title, description });
  }

  editTimeline(slug, data) {
    return agent.Timeline.edit(slug, data).then(timeline => {
      this.timelineRegistry.set(slug, timeline);
    });
  }

  deleteTimeline(slug) {
    this.timelineRegistry.delete(slug);
    return agent.Timeline.delete(slug);
  }

  loadTimelines() {
    this.isLoading = true;
    if (this.timelinePage === 0) {
      this.timelineRegistry.clear();
    }
    return agent.Timeline.all(this.timelinePage)
      .then(({ timelines, timelinesCount }) => {
        if (timelines.length === 0) {
          this.hasNextPage = false;
          return;
        }
        this.count = timelinesCount;
        timelines.forEach(timeline => {
          this.timelineRegistry.set(timeline.slug, timeline);
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  loadTimelineEvents(slug) {
    this.isLoading = true;
    this.timelineEventsRegistry.clear();
    this.currentTimeline = null;

    return agent.Timeline.getAllEvents(slug)
      .then(({ events, timeline }) => {
        events.forEach(event => {
          this.timelineEventsRegistry.set(event.id, event);
        });
        this.setCurrentTimeline(timeline);
        return events;
      })
      .finally(() => (this.isLoading = false));
  }

  loadTimelineJSEvents(slug) {
    this.isLoading = true;
    this.timelineEventsRegistry.clear();
    const existingTimelineEvents = this.timelineEventsRegistry.get(slug);
    if (existingTimelineEvents) return Promise.resolve(existingTimelineEvents);

    return agent.Timeline.getAllTJSEvents(slug).then(events => {
      events.forEach(event => {
        this.timelineEventsRegistry.set(event.id, event);
      });
      return events;
    });
  }

  createEvent(slug, data) {
    return agent.Timeline.createEvent(slug, data).then(event => {
      this.timelineEventsRegistry.set(event.id, event);
    });
  }

  editEvent(id, data) {
    return agent.Timeline.editEvent(id, data).then(event => {
      this.timelineEventsRegistry.set(event.id, event);
    });
  }

  deleteEvent(id) {
    this.timelineEventsRegistry.delete(id);
    return agent.Timeline.deleteEvent(id);
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return agent.Timeline.upload(formData);
  }

  getFiles() {
    this.isLoading = true;
    this.filesRegistry.clear();
    return agent.Profile.files()
      .then(files => {
        files.forEach(file => {
          this.filesRegistry.set(file.id, file);
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

decorate(TimelineStore, {
  isLoading: observable,
  hasNextPage: observable,
  count: observable,
  timelinePage: observable,
  timelineRegistry: observable,
  currentTimeline: observable,
  timelineEventsRegistry: observable,

  timelines: computed,
  events: computed,
  setPage: action,
  clear: action,
  createTimeline: action,
  editTimeline: action,
  setCurrentTimeline: action,
  loadTimelines: action,
  loadTimelineEvents: action,
  loadTimelineJSEvents: action,
  createEvent: action,
  editEvent: action,
  deleteEvent: action,
  uploadFile: action,
  getFiles: action,

  files: computed
});

export const timelineStore = new TimelineStore();

export default createContext(timelineStore);
