import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultMetaTags = () => (
  <React.Fragment>
    <Helmet titleTemplate="%s | CNA Timelines" defaultTitle="CNA Timelines" />
    <meta property="description" content="Timelines for CNA" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://cna.teliportme.com`} />
    <meta property="og:title" content="Timelines for CNA" />
    <meta property="og:description" content="Timelines for CNA" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://cna.teliportme.com" />
    <meta property="twitter:title" content="CNA Timelines" />
    <meta property="twitter:description" content="Timelines for CNA" />
  </React.Fragment>
);

export default DefaultMetaTags;
