import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import LoadingSpinner from './components/LoadingSpinner';
import CustomBrowsingRouter from './components/CustomBrowserRouter';
import MetaTags from './components/DefaultMetaTags';
import withTracker from './components/withTracker';
import CommonStore from './stores/commonStore';
import UserStore from './stores/userStore';
const Header = lazy(() => import('./components/Header'));
const Page404 = lazy(() => import('./containers/Page404'));
const Login = lazy(() => import('./containers/Login'));
const Signup = lazy(() => import('./containers/Signup'));
const Dashboard = lazy(() => import('./containers/Dashboard'));
const EditTimeline = lazy(() => import('./containers/EditTimeline'));
const ViewTimeline = lazy(() => import('./containers/ViewTimeline'));
const MediaLibrary = lazy(() => import('./containers/MediaLibrary'));

const AsyncHeader = props => (
  <React.Suspense fallback={<div />}>
    <Header {...props} />
  </React.Suspense>
);

const DefaultLayout = observer(props => {
  return (
    <React.Fragment>
      <AsyncHeader />
      <Route {...props} />
    </React.Fragment>
  );
});

const PrivateRoute = observer(props => {
  const commonStore = useContext(CommonStore);
  const userStore = useContext(UserStore);

  useEffect(() => {
    if (commonStore.token) {
      userStore.pullUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore]);

  return (
    <React.Fragment>
      {commonStore.appLoaded && userStore.currentUser ? (
        <React.Fragment>
          <AsyncHeader />
          <Route {...props} />
        </React.Fragment>
      ) : (
        commonStore.appLoaded && (
          <Redirect to={`/login?to=${props.location.pathname}`} />
        )
      )}
    </React.Fragment>
  );
});

const App = () => {
  const commonStore = useContext(CommonStore);
  const userStore = useContext(UserStore);

  let notificationDOMRef = React.createRef();

  useEffect(() => {
    userStore.pullUser();
  });

  autorun(() => {
    if (commonStore.snackMessage.message !== null) {
      notificationDOMRef.current &&
        notificationDOMRef.current.addNotification(commonStore.snackMessage);
      commonStore.setSnackMessage();
    }
  });

  return (
    <CustomBrowsingRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <div className="App w-100 center h-100 flex flex-column bt">
          <ReactNotification ref={notificationDOMRef} />
          <MetaTags />
          <Switch>
            <DefaultLayout
              path="/signup-cna-timeline"
              component={withTracker(Signup)}
            />
            <DefaultLayout path="/login" component={withTracker(Login)} />
            <PrivateRoute
              path="/dashboard"
              component={withTracker(Dashboard)}
            />
            <PrivateRoute
              path="/edit/:slug"
              component={withTracker(EditTimeline)}
            />
            <Route path="/view/:slug" component={withTracker(ViewTimeline)} />
            <PrivateRoute
              path="/medialibrary"
              component={withTracker(MediaLibrary)}
            />
            <DefaultLayout exact path="/" component={withTracker(Login)} />
            <DefaultLayout component={withTracker(Page404)} />
          </Switch>
        </div>
      </Suspense>
    </CustomBrowsingRouter>
  );
};

export default App;
